import { Card, CardContent, Button } from "@mui/material";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import CustomDataGrid from "components/datagrid/CustomGrid";
// import { modelImportTableRows } from "helpers/constants";
import { useEffect, useState } from "react";
import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import { styled } from "@mui/system";

// icon
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";

//
import _get from "lodash/get";

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  borderRadius: "8px",
  fontSize: "14px",
  padding: "10px 28px",
  backgroundColor: "#219653",
  color: "#fff",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#142E56",
  },
  "&:not(:hover)": {
    color: "#fff",
  },
}));

const ModelImportHeader = ({
  handleSendCalls,
  data,
  selectedRows = [],
  selectedStockNameNumber,
  setFetchedData,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  if (!data || data.length === 0 || !selectedStockNameNumber) {
    return null; // Return null if required props are not available
  }

  const counts = data.reduce((acc, item) => {
    if (selectedRows.includes(item.id)) {
      acc[item.risk_profile_name] = (acc[item.risk_profile_name] || 0) + 1;
    }
    return acc;
  }, {});

  const handleButtonClick = () => {
    handleSendCalls(null); // Call your function
    setIsButtonDisabled(true); // Disable the button after the first click
  };

  return (
    <Box
      sx={{
        backgroundColor: "#E6FFE3",
        m: 2,
        height: "78px",
        padding: "7px 20px",
        border: "1px solid #219653",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: "12px",
        }}
      >
        <Box>
          <Text fontSize="16px" fontWeight="400" color="#676C76" mb={2}>
            {selectedStockNameNumber} customers for {data[0].symbol} stocks have
            been selected for the trade
          </Text>
          <Text sx={{ fontSize: "16px", fontWeight: "500", color: "#242424" }}>
            {counts["Aggressive"] || 0} aggressive & {counts["Moderate"] || 0}{" "}
            moderate risk profile & {counts["Conservative"] || 0} Conservative
            customers have been selected for the trade
          </Text>
        </Box>
        <Box sx={{ mt: 1 }}>
          <StyledButton onClick={handleButtonClick} disabled={isButtonDisabled}>
            Send Calls
          </StyledButton>
        </Box>
      </Box>
    </Box>
  );
};

const SelectCustomerSendCallsTable = (props) => {
  const {
    customerColumns,
    onRowSelectionModelChange,
    data,
    selectedRows,
    handleSendCalls,
    handleStackCall,
    StackList,
    backtoMainScreen,
    productId, // Product id
    mode, // IMPORT, SINGLE
    isEditTradeDrawerOpen,
    onEditIsTradeDrawerOpen,
    setFetchedData,
  } = props;

  const [StackTradeId, setStackTradeId] = useState();
  const [expandedTradeId, setExpandedTradeId] = useState(null);
  const [tradesPaginationModel, setTradesPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [tableData, setTableData] = useState();
  const [isCustomGridVisible, setIsCustomGridVisible] = useState(false);

  const [getTradeDetailsUsingId, { data: tradeTableData }] =
  tradesApiAction.getTradeDetailsUsingId();

  const [deleteTradeCalls] = tradesApiAction.deleteTrade();
  // const [getTradeTableUsingId, { data: tradeTableData, error }] = tradesApiAction.getTradeUsingId(); // fetch data to display details
  const [getTradeTableUsingId] = tradesApiAction.getTradeUsingId(); // fetch Data to update send call

  const [getTradeTableUsingIdd] = tradesApiAction.getTradeUsingIds();

  const selectedStockNameNumber = selectedRows?.length;
  // const tradeData = StackList?.result?.filter((trade) => trade.flag === 0);

  const onViewDetail = async (tradeId) => {
    if (expandedTradeId === tradeId) {
      setExpandedTradeId((prevTradeId) =>
        prevTradeId === tradeId ? null : tradeId
      );
      setIsCustomGridVisible(false); // Hide the custom grid
      return;
    }

    const res = await getTradeTableUsingId({
      productId,
      StackTradeId: tradeId,
    });
    if (res && res.data && res.data.result) {
      onRowSelectionModelChange([]);
      updateTableListData(res.data.result);
    }

    setStackTradeId(tradeId);
    setExpandedTradeId((prevTradeId) =>
      prevTradeId === tradeId ? null : tradeId
    );
    setIsCustomGridVisible(true); // Show the custom grid
  };

  const onEditDetail = async (tradeId) => {
    if (!isCustomGridVisible) {
      alert("Please view the details first.");
      return;
    }

    const res = await getTradeTableUsingIdd({
      productId,
      StackTradeId: tradeId,
    });


    if (res && res.data && res.data.result) {
      onRowSelectionModelChange([]);
      updateTableListData(res.data.result);
      setFetchedData(res.data.result);
    }

    setStackTradeId(tradeId);
    onEditIsTradeDrawerOpen({ tradeId: tradeId });
  };

  //---------------------------> Stack Actions handle
  // useEffect(() => {
  //   if (StackTradeId) {
  //      getTradeTableUsingId({StackTradeId, productId});
  //   }
  // }, []);

  // update table listing data.
  const updateTableListData = (data = []) => {
    if (data && data?.length) {
      const id = data.map((d) => d.customer_id);

      onRowSelectionModelChange(id);
      setTableData(data);
    }
  };


  const tradeDataForTable = tradeTableData?.result || [];

  const tradeDataForTableWithId = tradeDataForTable.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  //delete stack trade for green stack
    
  const handleDeleteTrade = async (tradeDataForTableWithId) => {
    const payload = {
      tradeIds: tradeDataForTableWithId.customers.map((customer) => ({
        customer_id: customer,
        trade_id: tradeDataForTableWithId.trade_id,
      })),
    };
  
    try {
      // Call deleteTrade API
      const response = await deleteTradeCalls(payload);

      if (response) {
        // Refresh the page after successful deletion
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting trade:", error);
    }
  };

  const handleStackCallWithApiResponse = async (tradeId) => {
    // setStackTradeId(tradeId);
    // console.log("onRowSelectionModelChange =>", {expandedTradeId,tradeId, selectedRows});
    try {
      const res = await getTradeTableUsingId({
        productId,
        StackTradeId: tradeId,
      });
      let tradeData = _get(res, "data.result", []);

      // check if send calls & view details ID are same.
      if (expandedTradeId === tradeId) {
        if (selectedRows && selectedRows.length) {
          tradeData = getTableDataWithId(tradeData);
          tradeData = tradeData?.filter((d) => selectedRows.includes(d.id));
        } else {
          return;
        }
      }

      if (tradeData && tradeData?.length) {
        handleStackCall(tradeId, tradeData);
      }
    } catch (error) {
      console.error("Error fetching trade details:", error);
    }
  };

  const getTableDataWithId = (data = []) => {
    const tableDataWithId = data.map((item, index) => ({
      ...item,
      id: item.customer_id,
    }));
    return tableDataWithId;
  };

  const StackCallList =
    StackList?.result?.filter((trade) => trade.flag === 0) || [];
  const tableDataWithId = getTableDataWithId(tableData);

  console.log("Data check => ", selectedRows, tableDataWithId);
  //---------------------------> Stack Actions handle
  return (
    <Box>
      {StackCallList && StackCallList.length ? (
        <>
          {/* back to main page. */}
          <Box
            sx={{
              marginTop: "10px",
              cursor: "pointer",
              position: "sticky",
              top: "0px",
              backgroundColor: "#ffffff80",
              zIndex: "9",
              display: "inline-block",
            }}
          >
            <KeyboardBackspaceSharpIcon onClick={backtoMainScreen} />
          </Box>
          <Stack>
            {StackCallList.map((trade) => (
              <div key={trade.trade_id}>
                <Card
                  key={trade.trade_id}
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  <CardContent
                    sx={{
                      backgroundColor: "#E6FFE3",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #219653",
                      borderRadius: "8px",
                      padding: "9px 16px !important",
                    }}
                  >
                    <Text variant="body2" component="h2" sx={{ flex: 1 }}>
                      Stock: {trade.symbol}
                    </Text>
                    <Text
                      variant="body2"
                      color="textSecondary"
                      sx={{ flex: 1 }}
                    >
                      Total Customers: {trade.total_customer}
                    </Text>

                    <Box
                      sx={{
                        flex: 2,
                        display: "flex",
                        justifyContent: "end",
                        gap: "40px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ textTransform: "unset", width: "143px" }}
                        onClick={() => handleDeleteTrade(trade)}
                        disabled={
                          !isCustomGridVisible ||
                          expandedTradeId !== trade.trade_id
                        }
                      >
                        Delete
                      </Button>

                      <Button
                        onClick={() => onEditDetail(trade.trade_id)}
                        variant="contained"
                        color="primary"
                        sx={{ textTransform: "unset", width: "143px" }}
                        disabled={
                          !isCustomGridVisible ||
                          expandedTradeId !== trade.trade_id
                        }
                      >
                        Edit Detail
                      </Button>

                      <Button
                        onClick={() => onViewDetail(trade.trade_id)}
                        variant="contained"
                        color="primary"
                        sx={{ textTransform: "unset", width: "143px" }}
                      >
                        {expandedTradeId === trade.trade_id
                          ? "Close"
                          : "View Detail"}
                      </Button>

                      <Button
                        onClick={() =>
                          handleStackCallWithApiResponse(trade.trade_id)
                        }
                        variant="contained"
                        sx={{
                          backgroundColor: "#219653",
                          textTransform: "unset",
                        }}
                        disabled={
                          expandedTradeId === trade.trade_id &&
                          !selectedRows?.length
                        }
                      >
                        Send Calls
                      </Button>
                    </Box>
                  </CardContent>
                </Card>

                {expandedTradeId === trade.trade_id &&
                  tableDataWithId &&
                  tableDataWithId.length && (
                    <CustomDataGrid
                      autoHeight
                      list={tableDataWithId}
                      columnHeaderHeight={46}
                      rowHeight={60}
                      columns={customerColumns}
                      checkboxSelection
                      onRowSelectionModelChange={onRowSelectionModelChange}
                      selectedRows={selectedRows}
                      pagination
                      rowCount={tableDataWithId?.length || 0}
                      paginationMode="client"
                      paginationModel={tradesPaginationModel}
                      onPaginationModelChange={setTradesPaginationModel}
                    />
                  )}
              </div>
            ))}
          </Stack>
        </>
      ) : null}

      {/* While adding Data */}
      {data && customerColumns && (
        <Card sx={{ mt: "15px" }}>
          <Stack>
            <ModelImportHeader
              handleSendCalls={handleSendCalls}
              data={data}
              selectedRows={selectedRows}
              selectedStockNameNumber={selectedStockNameNumber}
            />

            <CustomDataGrid
              autoHeight
              list={data}
              columnHeaderHeight={46}
              rowHeight={60}
              columns={customerColumns}
              checkboxSelection
              onRowSelectionModelChange={onRowSelectionModelChange}
              selectedRows={selectedRows}
              // pagination
              pagination
              rowCount={data?.length || 0}
              paginationMode="client" // client, server
              paginationModel={tradesPaginationModel}
              onPaginationModelChange={setTradesPaginationModel}
            />
          </Stack>
        </Card>
      )}
    </Box>
  );
};

export default SelectCustomerSendCallsTable;
