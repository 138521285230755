import Box from "components/common/Box";
import React, { useEffect, useState } from "react";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import CustomGrid from "components/datagrid/CustomGrid";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { uuid } from "helpers/utility";
import { useLocation } from "react-router-dom";
import { CustomerPortfolioApiAction } from "stores/redux/apiSlices/CustomerPortfolio/portfolioApiSlice";

const CustomerDetail = ({ productId ,customerId, memberID }) => {
  const [getPerformance, getDetailTableDetail = []] =
    CustomerPortfolioApiAction.getDetailAPI();
  const detailTable = getDetailTableDetail?.data?.data ?? [];
  const detailTotalCount = getDetailTableDetail?.data?.totalRecords;
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const DetailTableHeader = [
    {
      field: "stock_name",
      headerName: "Stock Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.stock_name}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "last_price",
      headerName: "Current NAV",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.last_price} 
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "week_52_high",
      headerName: "52 week high",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.week_52_high}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "week_52_low",
      headerName: "52 week low",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.week_52_low}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "annualized_returns_1",
      headerName: "1 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["annualized_returns_1"] !== undefined &&
            params?.row?.["annualized_returns_1"] !== 0 &&
            (params?.row?.["annualized_returns_1"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["annualized_returns_1"]
                    ? `${Math.abs(params?.row?.["annualized_returns_1"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["annualized_returns_1"]
                    ? `${Math.abs(params?.row?.["annualized_returns_1"])} %`
                    : ""}
                </Text>
              </Stack>
            ))}
        </>
      ),
    },
    {
      field: "annualized_returns_3",
      headerName: "3 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["annualized_returns_3"] !== undefined &&
            params?.row?.["annualized_returns_3"] !== 0 &&
            (params?.row?.["annualized_returns_3"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["annualized_returns_3"]
                    ? `${Math.abs(params?.row?.["annualized_returns_3"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {" "}
                  {params?.row?.["annualized_returns_3"]
                    ? `${Math.abs(params?.row?.["annualized_returns_3"])} %`
                    : ""}
                </Text>
              </Stack>
            ))}
        </>
      ),
    },
    {
      field: "annualized_returns_5",
      headerName: "5 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["annualized_returns_5"] &&
          params?.row?.["annualized_returns_5"] !== 0 ? (
            params?.row?.["annualized_returns_5"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["annualized_returns_5"]
                    ? ` ${Math.abs(params?.row?.["annualized_returns_5"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["annualized_returns_5"]
                    ? ` ${Math.abs(params?.row?.["annualized_returns_5"])} %`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "return_since_inception",
      headerName: "Returns since inception",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.return_since_inception &&
          params?.row?.return_since_inception !== 0 ? (
            params?.row?.return_since_inception > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.return_since_inception
                    ? ` ${Math.abs(params?.row?.return_since_inception)} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.return_since_inception
                    ? ` ${Math.abs(params?.row?.return_since_inception)} %`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
  ];

  const AlgoDetailTableHeader = [
    {
      field: "symbol",
      headerName: "Stock Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.symbol}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "last_price",
      headerName: "Current NAV",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.last_price
                ? `₹ ${Math.abs(params?.row?.last_price)}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "52WeeksHighestHighPrice",
      headerName: "52 week high",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.["52WeeksHighestHighPrice"]
                ? `₹ ${Math.abs(params?.row?.["52WeeksHighestHighPrice"])}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "52WeeksLowestLowPrice",
      headerName: "52 week loss",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.["52WeeksHighestHighPrice"]
                ? `₹ ${Math.abs(params?.row?.["52WeeksHighestHighPrice"])}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "1YearReturns",
      headerName: "1 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["1YearReturns"] !== undefined &&
            params?.row?.["1YearReturns"] !== 0 &&
            (params?.row?.["1YearReturns"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["1YearReturns"]
                    ? `${Math.abs(params?.row?.["1YearReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["1YearReturns"]
                    ? `${Math.abs(params?.row?.["1YearReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ))}
        </>
      ),
    },
    {
      field: "3YearsReturns",
      headerName: "3 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["3YearsReturns"] !== undefined &&
            params?.row?.["3YearsReturns"] !== 0 &&
            (params?.row?.["3YearsReturns"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["3YearsReturns"]
                    ? `${Math.abs(params?.row?.["3YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {" "}
                  {params?.row?.["3YearsReturns"]
                    ? `${Math.abs(params?.row?.["3YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ))}
        </>
      ),
    },
    {
      field: "5YearsReturns",
      headerName: "5 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["5YearsReturns"] &&
          params?.row?.["5YearsReturns"] !== 0 ? (
            params?.row?.["5YearsReturns"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["5YearsReturns"]
                    ? ` ${Math.abs(params?.row?.["5YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["5YearsReturns"]
                    ? ` ${Math.abs(params?.row?.["5YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "return_since_inception",
      headerName: "Returns since inception",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.return_since_inception &&
          params?.row?.return_since_inception !== 0 ? (
            params?.row?.return_since_inception > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.return_since_inception
                    ? ` ${Math.abs(params?.row?.return_since_inception)} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.return_since_inception
                    ? ` ${Math.abs(params?.row?.return_since_inception)} %`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
  ];

  const modifiedDetailTableData = detailTable.map((row) => ({
    ...row,
    id: uuid(),
  }));

  const CustomerHeader = () => {
    return (
      <>
        <Box sx={{ p: 2 }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Text
              sx={{ color: "primary", mr: 1, fontWeight: 500 }}
              color="primary"
              variant="button"
            >
              Detail{" "}
            </Text>
          </Stack>
        </Box>
      </>
    );
  };

  const getColumns = (productId) => {
    if (productId === 2) {
      return DetailTableHeader;
    } else if (productId === 3) {
      return AlgoDetailTableHeader;
    } else if (productId === 1) {
      return DetailTableHeader;
    } else {
      return;
    }
  };

  React.useEffect(() => {
    getPerformance({
      user_id: memberID,
      page: paginationModel?.page + 1,
      pageSize: paginationModel?.pageSize,
      product_id : productId,
    });
  }, [paginationModel.page, paginationModel.pageSize]);

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPaginationModel?.page,
      pageSize: newPaginationModel.pageSize,
    }));
  };

  return (
    <div>
      <Stack>
        <CustomGrid
          autoHeight
          list={modifiedDetailTableData}
          getRowId={(row) => row.id}
          columnHeaderHeight={46}
          rowHeight={72}
          isLoading={false}
          columns={getColumns(productId)}
          header={CustomerHeader}
          rowCount={detailTotalCount}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationChange}
        />
      </Stack>
    </div>
  );
};

export default CustomerDetail;
