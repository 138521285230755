import React from "react";
import Grid from "components/common/Grid";
import Box from "components/common/Box";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Button from "components/common/Button";
import Card from "components/common/Card";
import Radio from "components/common/Radio";
import FileUpload from "components/common/FileUpload";
import DatePicker from "components/common/DatePicker";
import { Link } from "@mui/material";
import { styled } from "@mui/material";
import CheckboxCard from "components/common/CheckboxCard";
import { useSelector } from "react-redux";

const JoiningDateText = styled(Text)(({}) => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "10px",
}));
const PlanText = styled(Text)(({}) => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
}));
const IndianText = styled(Text)(({}) => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#242424",
  marginRight: "20px",
}));
const CustomerTypeText = styled(Text)(({}) => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "12px",
}));
const CustomerCategoryText = styled(Text)(({}) => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#242424",
  marginRight: "20px",
}));
const AdvisoryCustomerText = styled(Text)(({}) => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#242424",
  marginRight: "8px",
}));
const PanCardText = styled(Text)(({}) => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "12px",
}));
const PanFormatText = styled(Text)(() => ({
  color: "#242424",
  fontSize: "12px",
  fontWeight: 500,
  marginBottom: "12px",
}));
const PreviousButton = styled(Button)(({}) => ({
  borderRadius: "8px",
  textTransform: "capitalize",
  padding: "18px 28px",
  marginRight: "20px",
  fontWeight: 600,
  width: "160px",
  border: "1.3px solid #142E56",
}));
const SubmitButton = styled(Button)(({}) => ({
  backgroundColor: "#142E56",
  color: "#FFF",
  fontSize: "14px",
  borderRadius: "8px",
  padding: "18px 28px",
  width: "260px",
  mb: 8,
}));

function CustomerOtherDetails(props) {
  const {
    handleChange,
    handleStepChange,
    handleSubmit,
    formData,
    formError,
    downloadAgreementTemplate,
  } = props;


  const userDetails = useSelector((state) => state.commonReducer.userDetails);
  
  return (
    <>
      <Box
        sx={{
          paddingRight: "100px",
          paddingLeft: "100px",
          backgroundColor: "#F7F8FF",
          mt: 9,
        }}
      >
        {/* <Box> */}
        <Box sx={{ backgroundColor: "#F7F8FF" }}>
          <Text
            sx={{
              color: "#242424",
              fontSize: "24px",
              fontWeight: 500,
              mb: "6px",
            }}
          >
            Other details
          </Text>
          <Text
            sx={{
              color: "#676C76",
              fontWeight: 400,
              fontSize: "16px",
              mb: "30px",
            }}
          >
            Fill out all the investment and other details to start your journey
            with us.{" "}
          </Text>
        </Box>

        <Card
          sx={{
            p: 4,
            border: "1px solid #EAECF0",
            borderRadius: "16px",
            mb: "36px",
          }}
        >
          <Grid container>
            {/* <Grid item md={6} sx={{ mb: "36px" }}>
              <Box sx={{ width: "100%", mb: "0px" }}>
                <JoiningDateText>Joining date</JoiningDateText>
                <DatePicker
                  value={formData.joiningDate}
                  sx={{ width: "100%" }}
                  onChange={(d) => {
                    handleChange({ name: "joiningDate", value: d["$d"] });
                  }}
                />
                {formError.joiningDate && (
                  <Text variant="small" color="red" py={1}>
                    {formError.joiningDate}
                  </Text>
                )}
              </Box>
            </Grid>
            <Grid item md={6}></Grid> */}

            <Grid item md={6} sx={{ mb: "36px" }}>
              <Box sx={{ width: "100%", mb: "0px" }}>
                <JoiningDateText>Capital</JoiningDateText>
                <TextField
                  placeholder="Enter Capital amount in Rupees"
                  value={formData.capital}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    // Validate if the entered value is numeric
                    if (/^\d*$/.test(enteredValue)) {
                      handleChange({ name: "capital", value: enteredValue });
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <>
                        <span style={{ marginRight: "5px" }}>₹</span>
                      </>
                    ),
                  }}
                />
                {formError.capital && (
                  <Text variant="small" color="red" py={1}>
                    {formError.capital}
                  </Text>
                )}
              </Box>
            </Grid>

            <Grid item md={12}>
              <Box sx={{ width: "100%", mb: "0px" }}>
                <JoiningDateText>Type of Invesment</JoiningDateText>
              </Box>
            </Grid>

            <Grid item md={3.8} sx={{ mr: "12px" }}>
              <CheckboxCard
                title="Custom"
                value={formData.isCustomInvestmentSelected}
                handleClick={() => {
                  handleChange({
                    name: "isCustomInvestmentSelected",
                    value: formData.isCustomInvestmentSelected ? null : 1,
                  });
                }}
              />
            </Grid>
            <Grid item md={3.8} sx={{ mr: "12px" }}>
              <CheckboxCard
                title="Algo (F&O)"
                value={formData.isAlgoSelected}
                handleClick={() => {
                  handleChange({
                    name: "isAlgoSelected",
                    value: formData.isAlgoSelected ? null : 3, // Set to 1 if not already selected
                  });
                }}
              />
            </Grid>
            <Grid item md={3.8} sx={{ mr: "12px" }}>
              <CheckboxCard
                title="Model Portfolio"
                value={formData.isModelPortfolioSelected}
                handleClick={() => {
                  handleChange({
                    name: "isModelPortfolioSelected",
                    value: formData.isModelPortfolioSelected ? null : 2,
                  });
                }}
              />
            </Grid>
            {!formData.isCustomInvestmentSelected &&
              !formData.isAlgoSelected &&
              !formData.isModelPortfolioSelected && (
                <Text variant="small" color="red" py={1}>
                  {formError.isCustomInvestmentSelected}
                </Text>
              )}

            <Grid item md={12} sx={{ mb: "36px", mt: "36px" }}>
            {userDetails.mappedTo !== "SUBBROKER" && (
              <Box sx={{ mb: "0px" }}>
                <CustomerTypeText>Customer type</CustomerTypeText>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Radio
                    value="Advisory"
                    sx={{ mr: "8px" }}
                    checked={formData.customerType === "Advisory"}
                    onChange={() => {
                      handleChange({
                        name: "customerType",
                        value: "Advisory",
                      });
                    }}
                  />

                  <AdvisoryCustomerText>Advisory customer</AdvisoryCustomerText>
                </Box>
              </Box>
            )}
            </Grid>

            <Grid item md={12} sx={{ mb: "36px" }}>
              <Box sx={{ width: "100%" }}>
                <PlanText sx={{ mb: "12px" }}>Customer residency</PlanText>

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Radio
                    value="Indian"
                    name="customerResidency"
                    sx={{ mr: "8px" }}
                    checked={formData.customerResidency === "Indian"}
                    onChange={() => {
                      handleChange({
                        name: "customerResidency",
                        value: "Indian",
                      });
                    }}
                  />

                  <IndianText>Indian</IndianText>
                  <Radio
                    value="NonIndia"
                    name="customerResidency"
                    sx={{ mr: "8px" }}
                    checked={formData.customerResidency === "NonIndia"}
                    onChange={() => {
                      handleChange({
                        name: "customerResidency",
                        value: "NonIndia",
                      });
                    }}
                  />

                  <IndianText>Non-indian</IndianText>

                  <Radio
                    value="Nri"
                    name="customerResidency"
                    sx={{ mr: "8px" }}
                    checked={formData.customerResidency === "Nri"}
                    onChange={() => {
                      handleChange({
                        name: "customerResidency",
                        value: "Nri",
                      });
                    }}
                  />

                  <IndianText>NRI</IndianText>
                </Box>
                {formError.customerResidency && (
                  <Text variant="small" color="red" py={1}>
                    {formError.customerResidency}
                  </Text>
                )}
              </Box>
            </Grid>

            <Grid item md={12} sx={{ mb: "36px" }}>
              <Box sx={{ width: "100%" }}>
                <PlanText sx={{ mb: "10px" }}>Customer category</PlanText>

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Radio
                    value="Individual"
                    sx={{ mr: "8px" }}
                    checked={formData.customerCategory === "Individual"}
                    onChange={() => {
                      handleChange({
                        name: "customerCategory",
                        value: "Individual",
                      });
                    }}
                  />

                  <IndianText>Individual</IndianText>
                  <Radio
                    value="NonIndividual"
                    sx={{ mr: "8px" }}
                    checked={formData.customerCategory === "NonIndividual"}
                    onChange={() => {
                      handleChange({
                        name: "customerCategory",
                        value: "NonIndividual",
                      });
                    }}
                  />

                  <IndianText>Non - Individual</IndianText>
                </Box>
                {formError.customerCategory && (
                  <Text variant="small" color="red" py={1}>
                    {formError.customerCategory}
                  </Text>
                )}
              </Box>
            </Grid>

            <Grid item md={5.8} sx={{ mb: "36px", mr: "30px" }}>
              <Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <PanCardText>PAN Card</PanCardText>
                  <PanFormatText sx={{ mt: "2px", ml: "6px" }}>
                    {/* (PDF and Image Format only) */}
                    (PDF and Image Format only)
                  </PanFormatText>
                </Box>
                <FileUpload
                  name="panNo"
                  handleChange={handleChange}
                  acceptedFileTypes={[
                    "application/pdf",
                    "image/jpeg",
                    "image/jpg",
                    "image/png",
                  ]}
                />
                {formError.panNo && (
                  <Text variant="small" color="red" py={1}>
                    {formError.panNo}
                  </Text>
                )}
              </Box>
            </Grid>

            <Grid item md={5.8} sx={{ mb: "36px" }}>
              <Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <PanCardText>Aadhar Card</PanCardText>
                  <PanFormatText sx={{ mt: "2px", ml: "6px" }}>
                    {/* (PDF and Image Format only) */}
                    (PDF and Image Format only)
                  </PanFormatText>
                </Box>
                <FileUpload
                  name="aadharCard"
                  handleChange={handleChange}
                  acceptedFileTypes={[
                    "application/pdf",
                    "image/jpeg",
                    "image/jpg",
                    "image/png",
                  ]}
                />
                {formError.aadharCard && (
                  <Text variant="small" color="red" py={1}>
                    {formError.aadharCard}
                  </Text>
                )}
              </Box>
            </Grid>

            {/* <Grid> */}
            <Grid item md={5.8} sx={{ mb: "36px", mr: "30px" }}>
              <Box sx={{ width: "100%" }}>
                <PlanText sx={{ mb: "12px" }}>PAN Number</PlanText>
                <TextField
                  placeholder="Enter PAN no"
                  // disabled
                  value={formData?.panCardNo}
                  onChange={(e) => {
                    const input = e.target.value.toUpperCase();
                    if (/^[0-9A-Za-z]{0,10}$/.test(input)) {
                      handleChange({
                        name: "panCardNo",
                        value: input,
                      });
                    }
                  }}
                  sx={{ borderRadius: "8px" }}
                />
                {formError.panCardNo && (
                  <Text variant="small" color="red" py={1}>
                    {formError.panCardNo}
                  </Text>
                )}
              </Box>
            </Grid>
            <Grid item md={5.8} sx={{ mb: "36px" }}>
              <Box sx={{ width: "100%" }}>
                <PlanText sx={{ mb: "12px" }}>Aadhar Number</PlanText>
                <TextField
                  placeholder="Enter Aadhaar no"
                  sx={{ borderRadius: "8px" }}
                  value={formData?.aadharNo}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d{0,12}$/.test(input)) {
                      handleChange({
                        name: "aadharNo",
                        value: input,
                      });
                    }
                  }}
                  // Add this line to enforce numeric keyboard
                />

                {formError.aadharNo && (
                  <Text variant="small" color="red" py={1}>
                    {formError.aadharNo}
                  </Text>
                )}
              </Box>
            </Grid>
            {/* </Grid> */}

            <Grid item md={5.8} sx={{ mb: "36px", mr: "30px" }}>
              <Box sx={{ width: "100%" }}>
                <PlanText sx={{ mb: "12px" }}>Address</PlanText>
                <TextField
                  placeholder="Enter Address"
                  // disabled
                  value={formData?.address}
                  onChange={(e) => {
                    const input = e.target.value;
                   
                      handleChange({
                        name: "address",
                        value: input,
                      });
                    
                  }}
                  sx={{ borderRadius: "8px" }}
                />
                {formError.address && (
                  <Text variant="small" color="red" py={1}>
                    {formError.address}
                  </Text>
                )}
              </Box>
            </Grid>

            {userDetails.mappedTo !== "SUBBROKER" && (
            <Grid item md={6} sx={{ mb: "36px" }}>
              <Box sx={{ display: "flex" }}>
                <Box
                  component="img"
                  alt="Exclamation"
                  src="/images/download.svg"
                />
                <Link
                  onClick={downloadAgreementTemplate}
                  href="#"
                  underline="always"
                  sx={{
                    color: "#0C53B4",
                    fontSize: "14px",
                    fontWeight: "500px",
                  }}
                >
                  {"Download format here"}
                </Link>
              </Box>
            </Grid>
              )}
            <Grid item md={6}></Grid>

            {userDetails.mappedTo !== "SUBBROKER" && (
            <Grid item md={5.8} sx={{ mb: "12px" }}>
              <Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <PanCardText>Sign agreement</PanCardText>

                  <PanFormatText sx={{ mt: "2px", ml: "6px" }}>
                    {/* (PDF & JPEG Format only) */}
                    (PDF and Image Format only)
                  </PanFormatText>
                </Box>
                <FileUpload
                  name="signAgreement"
                  handleChange={handleChange}
                  acceptedFileTypes={[
                    "application/pdf",
                    "image/jpeg",
                    "image/jpg",
                    "image/png"
                  ]}
                />
                {formError.signAgreement && (
                  <Text variant="small" color="red" py={1}>
                    {formError.signAgreement}
                  </Text>
                )}
              </Box>
            </Grid>
            )}
          </Grid>
        </Card>

        <Box sx={{ display: "flex", justifyContent: "flex-end", pb: "20px" }}>
          <PreviousButton
            variant="outlined"
            onClick={() => {
              handleStepChange(3);
            }}
          >
            Previous
          </PreviousButton>
          <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        </Box>

        {/* </Box> */}
      </Box>
    </>
  );
}

export default CustomerOtherDetails;
