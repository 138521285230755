import { Card, CardContent } from "@mui/material";
import Box from "components/common/Box";
import Button from "components/common/Button";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import CustomDataGrid from "components/datagrid/CustomGrid";
import { modelImportTableRows } from "helpers/constants";
import { useEffect, useState } from "react";
import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import StockTradeDrawer from "./customStockTradeDrawer";

// icon
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";

// Component definition
const TradeUpdateStackTable = (props) => {
  const {
    StackList, // List of trades
    customerColumns, // Columns for the data grid
    onRowSelectionModelChange, // Callback function for row selection change
    backtoMainScreen,
    productId,
    setFetchedData,
    onEditUpdateIsTradeDrawerOpen,
  } = props;

  // State variables
  const [StackTradeId, setStackTradeId] = useState(); // ID of the selected trade
  const [expandedTradeIdUpdate, setExpandedTradeIdUpdate] = useState(null); // ID of the expanded trade
  const [updatedTradeId, setupdatedTradeId] = useState(null); // ID of the expanded trade
  const [sideDrawerOpen1, setSideDrawerOpen1] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [tableData, setTableData] = useState();
  const [isCustomGridVisible, setIsCustomGridVisible] = useState(false);
  const [isGridOpen, setIsGridOpen] = useState({}); // Track grid open state


  const [tradesPaginationModel, setTradesPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  // API call to delete the trade
  const [deleteTrade] = tradesApiAction.deleteTradeCalls(); // assuming you have an API endpoint for deleting trades

  // Function to handle the delete action

  const onViewDetail = (tradeId) => {
    setStackTradeId(tradeId);
    setExpandedTradeIdUpdate((prevTradeId) =>
      prevTradeId === tradeId ? null : tradeId
    );
    setIsGridOpen((prevState) => ({
      ...prevState,
      [tradeId]: prevState[tradeId] ? false : true, // Toggle grid open state
    }));
  };
  

  // API call to get trade table data based on trade ID
  const [getTradeDetailsUsingId, { data: tradeTableData }] =
    tradesApiAction.getTradeDetailsUsingId();

  const [getStackList] = tradesApiAction.getStackListApi();

  const [handleDataforUpdateCall] = tradesApiAction.getTradeUpdateData();
  const [getTradeTableUsingIdd] = tradesApiAction.getTradeUsingIds();

  useEffect(() => {
    if (StackTradeId) {
      getTradeDetailsUsingId({ productId, StackTradeId });
    }
  }, [StackTradeId]);

  const tradeData =
    StackList?.result?.filter((trade) => trade.flag === 1) || [];

  const tradeDataForTable = tradeTableData?.result || [];

  useEffect(() => {}, [StackList, tradeData]);

  const tradeDataForTableWithId = tradeDataForTable.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  const handleDeleteTrade = async (tradeDataForTableWithId) => {
    const payload = {
      tradeIds: tradeDataForTableWithId.customers.map((customer) => ({
        customer_id: customer,
        trade_id: tradeDataForTableWithId.trade_id,
      })),
    };
  
    try {
      // Call deleteTrade API
      const response = await deleteTrade(payload);
      console.log("response", response);

      if (response) {
        // Refresh the page after successful deletion
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting trade:", error);
    }
  };
  
  const openSidebar = async (trade_id) => {
    const id = trade_id;
    setupdatedTradeId(id);
    if (!id) {
      console.error("No trade ID provided.");
      return;
    }

    const payload = { trades: [parseInt(id)] };
    try {
      const response = await handleDataforUpdateCall(payload);
      console.log("API Response:", response);
      setApiResponse(response);
      setSideDrawerOpen1(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onEditDetail = async (tradeId) => {
    const res = await getTradeTableUsingIdd({
      productId,
      StackTradeId: tradeId,
    });

    console.log("ress", res);

    if (res && res.data && res.data.result) {
      onRowSelectionModelChange([]);
      updateTableListData(res.data.result);
      setFetchedData(res.data.result);
    }

    setStackTradeId(tradeId);
    onEditUpdateIsTradeDrawerOpen({ tradeId: tradeId });
  };

  const updateTableListData = (data = []) => {
    if (data && data?.length) {
      const id = data.map((d) => d.customer_id);
      onRowSelectionModelChange(id);
      setTableData(data);
    }
  };

  const recallDataOnUpdate = ({ tradeId }) => {
    getTradeDetailsUsingId({ productId, StackTradeId: tradeId });
    openSidebar(updatedTradeId);
  };

  const closeSideDrawer1 = () => {
    setSideDrawerOpen1(false);
  };

  const getTableDataWithId = (data = []) => {
    const tableDataWithId = data.map((item, index) => ({
      ...item,
      id: item.customer_id,
    }));
    return tableDataWithId;
  };

  console.log("getTableDataWithId", getTableDataWithId);

  const StackCallList =
    StackList?.result?.filter((trade) => trade.flag === 0) || [];
  const tableDataWithId = getTableDataWithId(tableData);

  return (
    <Box>
      {/* back to main page */}
      <Box
        sx={{
          marginTop: "10px",
          cursor: "pointer",
          position: "sticky",
          top: "0px",
          backgroundColor: "#ffffff80",
          zIndex: "9",
          display: "inline-block",
        }}
      >
        <KeyboardBackspaceSharpIcon onClick={backtoMainScreen} />
      </Box>

      <Stack>
        {tradeData.map((trade) => (
          <div key={trade.trade_id}>
            <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
              <CardContent
                sx={{
                  backgroundColor: "#FFF5E0",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #F2994A",
                  borderRadius: "8px",
                  padding: "9px 16px !important",
                }}
              >
                {/* Displaying trade details */}
                <Text variant="body2" component="h2" sx={{ flex: 1 }}>
                  stock: {trade.symbol}
                </Text>
                <Text variant="body2" color="textSecondary" sx={{ flex: 1 }}>
                  Total Customers: {trade.total_customer}
                </Text>

                <Box
                  sx={{
                    flex: 2,
                    display: "flex",
                    justifyContent: "end",
                    gap: "40px",
                    alignItems:"center"
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ textTransform: "unset", width: "143px" }}
                    onClick={() => handleDeleteTrade(trade)}
                    disabled={!isGridOpen[trade.trade_id]}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => onEditDetail(trade.trade_id)}
                    variant="contained"
                    color="primary"
                    sx={{ textTransform: "unset", width: "143px" }}
                    disabled={!isGridOpen[trade.trade_id]}
                  >
                    Edit Detail
                  </Button>
                  <Button
                    onClick={() => onViewDetail(trade.trade_id)}
                    variant="contained"
                    color="primary"
                    sx={{ width: "110px" }}
                  >
                    {expandedTradeIdUpdate === trade.trade_id
                      ? "Close"
                      : "View Detail"}
                  </Button>
                  <Button
                    onClick={() => openSidebar(trade.trade_id)}
                    variant="contained"
                    sx={{ backgroundColor: "#219653", whiteSpace: 'nowrap'}}
                  >
                    Update Trades
                  </Button>
                </Box>
              </CardContent>
            </Card>

            {expandedTradeIdUpdate === trade.trade_id && (
              <CustomDataGrid
                autoHeight
                list={tradeDataForTableWithId}
                columnHeaderHeight={46}
                rowHeight={60}
                columns={customerColumns}
                onRowSelectionModelChange={onRowSelectionModelChange}
                pagination
                rowCount={tradeDataForTableWithId?.length || 0}
                paginationMode="client"
                paginationModel={tradesPaginationModel}
                onPaginationModelChange={setTradesPaginationModel}
              />
            )}
          </div>
        ))}
      </Stack>

      <StockTradeDrawer
        sideDrawerOpen1={sideDrawerOpen1}
        closeSideDrawer1={closeSideDrawer1}
        // expandedTradeIdUpdate={expandedTradeIdUpdate}
        apiResponse={apiResponse}
        recallDataOnUpdate={recallDataOnUpdate}
        productId={productId}
      />
    </Box>
  );
};

export default TradeUpdateStackTable;
